<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký Order</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Link live stream cần tăng:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="object_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link dạng https://www.facebook.com/teamflash.ProE/videos/2445452429007890/"
                                />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Số lượng mắt cần tăng:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <select class="select-light form-control" v-model="quantity" required="required">
                                    <option v-for="(i, index) in 200" :key="index" :value="i * 50">{{
                                        (i * 50) | formatNumber
                                    }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Chọn thời gian duy trì mắt trên video trực tiếp:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <select class="col-xl-12 select-light form-control" v-model="time_up_eye">
                                    <option v-for="(option, index) in optionTimeEye" :value="option" :key="index"
                                        >{{ option }} Phút</option
                                    >
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Tình trạng video của bạn:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <select class="col-xl-12 select-light form-control" v-model="optionStatusVideo">
                                    <option value="false">Video đang phát trực tiếp</option>
                                    <option value="true">Video chưa phát</option>
                                </select>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Thời gian mỗi lần lên mắt:</h6>
                            </div>
                            <div class="col-md col-12">
                                <select class="col-xl-12 select-light form-control" v-model="time_delay">
                                    <option v-for="(option, index) in optionEyeSmart" :value="option" :key="index"
                                        >{{ option / 60 }} phút</option
                                    >
                                </select>
                                <h6 class="green font-13 mt-2">
                                    Lựa chọn này cho phép điều chỉnh số mắt lên chậm rãi và tăng dần đều từng phút sau
                                    khi phát trực tiếp. Sau khoảng thời gian đã chọn, số mắt xem sẽ giữ ổn định ở số mắt
                                    cao nhất của gói đã mua
                                </h6>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold green"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ buff <span class="bold green">{{ quantity }} Mắt</span> với giá
                                            <span class="green bold">{{ price | formatNumber }}</span> {{ currency }} /
                                            Mắt
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-red">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <strong
                                    ><b><i class="fa fa-info mr-1"></i>Giới thiệu: </b></strong
                                >
                                <br />- Là dịch vụ hỗ trợ chạy tăng mắt live stream của bạn<br />
                                <strong
                                    ><b><i class="mr-1"></i>Hướng dẫn: </b></strong
                                >
                                <br />- Số lượng mắt tối thiểu mỗi lần mua là 50 mắt , tối đa là 10,000 mắt. <br />- Hệ
                                thống chỉ tăng mắt / không xem video. <br />- Để giống người xem thật, số lượng mắt sẽ
                                được điều chỉnh lên xuống ngẫu nhiên quanh số mắt của bạn mua.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <livestreamlogs :panel="panel"></livestreamlogs>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError } from "../../../../helpers"
import { convertUsernameToFbId, orderBuffEyeV1 } from "../../../../api/services/facebook"
import livestreamlogs from "./livestream-logs"

export default {
    name: "livestream-order",
    components: { livestreamlogs },
    data() {
        return {
            object_id: "",
            panel: "order",
            notes: "",
            quantity: 50,
            time_delay: 120,
            checkTimeEye: true,
            optionTimeEye: [15, 30, 45, 60, 90, 120, 150, 180, 210, 240],
            optionEyeSmart: [120, 240, 360, 480, 600, 900, 1200, 1500, 1800],
            columnsTableLogs: [
                {
                    label: "#",
                    field: "index",
                    html: true
                },
                {
                    label: "Người đăng",
                    field: "username",
                    html: true
                },
                {
                    label: "Link live stream",
                    field: "object_id",
                    fb_link: true
                },
                {
                    label: "Số lượng",
                    field: "quantity",
                    numeric: true
                },
                {
                    label: "Thời gian duy trì",
                    field: "time_up_eye"
                },
                {
                    label: "Giá tiền",
                    field: "price",
                    numeric: true
                },
                {
                    label: "Thời gian",
                    field: "updated_at",
                    timeago: true
                }
            ],
            list_order_buff_eye: [],
            optionStatusVideo: false,
            time_up_eye: 15
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        price() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 73)
            let price = tool.prices.find(price => price.package_name.includes("buff_eyes_sv_kg"))
            return price.price
        },
        totalPrice() {
            return (this.time_up_eye / 15) * (this.price * this.quantity)
        }
    },
    methods: {
        order: async function() {
            if (this.object_id.length < 1) {
                this.$swal("Lỗi", "ID không được để trống", "error")
                return
            }
            if (this.quantity.length < 1) {
                this.$swal("Lỗi", "Số lượng không được để trống", "error")
                return
            }
            var postData = {
                object_id: this.object_id,
                quantity: this.quantity,
                time_delay: this.time_delay,
                type: this.optionStatusVideo ? true : false,
                time_up_eye: this.time_up_eye,
                note: this.notes
            }
            let data = await orderBuffEyeV1(postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", data.message, "success")
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        increaseDateByNumber(date, number = 0) {
            const dateInit = new Date(date).valueOf()
            const milisecondIncrease = number * 24 * 60 * 60 * 1000
            return new Date(dateInit + milisecondIncrease).toISOString()
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        },
        getFbId: async function(link) {
            if (link.match(/facebook/)) {
                this.$toastr.success("Hệ thống đang tự động get id cho link facebook bạn nhập vào!")
                if (link.indexOf("profile.php") > -1) {
                    var regex = (link + "&").match(/id=(\d*?)&/)
                    if (regex.length === 2) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.object_id = regex[1]
                        return true
                    } else {
                        return false
                    }
                } else {
                    var username = link.replace(
                        /https|http|\/|\\|www\.|mbasic\.|m\.facebook.com|facebook\.com|:|\?.*/gm,
                        ""
                    )
                    let data = await convertUsernameToFbId(username)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        this.object_id = data.data.id
                        return true
                    } else {
                        return false
                    }
                }
            } else return true
        }
    }
}
</script>
